#chart-filter-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#chart-filter-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#chart-filter-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

.chart-filter-tags {
  overflow-x: auto;
  white-space: nowrap;
  height: 42px;
}
#chart-filter-horizontal-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#chart-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#chart-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
