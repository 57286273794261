.table-view-table .ant-table-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.table-view-table .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.table-view-table .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

.table-view-table-prevent-resize table {
  height: calc(100vh - 16em) !important;
}
.table-view-table-prevent-resize .ant-table-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.table-view-table-prevent-resize .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.table-view-table-prevent-resize .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
.ant-table-column-sorters {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-view-table .ant-table-body {
  height: calc(100vh - 16em) !important;
}
.table-view-table .ant-table-row {
  min-height: 70px !important;
  height: 70px !important;
}
.table-view-table .ant-table-column-sorter-up, .table-view-table .ant-table-column-sorter-down{
  font-size: 16px !important;
}
.table-view-table .ant-table-column-sorter{
  display: none;
}

.custom-column-sorter{
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color .3s
}
.custom-column-sorter-inner{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.custom-column-sorter-up,
.custom-column-sorter-down,
.custom-sorter {
  font-size: 16px;
}
.custom-column-sorter-up+.custom-column-sorter-down {
  margin-top: -0.3em;
}
.sort-active{
  color: #1890ff;
}
.table-view-table td.ant-table-column-sort{
  background: transparent;
}