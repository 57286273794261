.table-select-data-empty-text {
  height: 500px;
}
.table-select-data .ant-table-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.table-select-data .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.table-select-data .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
.ant-table-column-sorters {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-select-data-prevent-resize table {
  height: 500px !important;
}
.table-select-data-prevent-resize .ant-table-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.table-select-data-prevent-resize .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.table-select-data-prevent-resize .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

/* .table-select-data-no-data .ant-table-expanded-row-fixed {
    height: 500px !important;
    width: 600px !important;
}
.table-select-data-no-data .ant-table-body {
    overflow-y: hidden !important;
}
.table-select-data-no-data .ant-table-body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.table-select-data-no-data .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.table-select-data-no-data .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
} */
