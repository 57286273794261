.setting-layout{
    position: fixed;
    bottom: 10px;
    right: 20px;
    display: flex;
    z-index: 999;
    gap: 15px;
    align-items: center;
  }
  .setting-icon svg{
    width: 2rem;
    height: 2rem;
    color: #555;
    cursor: pointer;
  }
  .full-screen-chart{
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 50%;
    border: none;
    outline: none;
    color: white;
    padding: 12px 12px;
    cursor: pointer;
  }
  .full-screen-chart-button{
    background-color: #092541;
  }
  .full-screen-chart-button:hover, .exit-full-screen-button:hover {
    background-color: dodgerblue;
    color: #FFF;
  }
  .full-screen-chart-button:active {
    background-color: #092541;
  }
  .exit-full-screen-button{
    background-color: #FFF;
    color: #40a9ff;
  }
  .full-screen-icon {
    font-size: 18px !important;
  }
  .setting-sub-menu{
    padding: 7px 20px;
    border-radius: 5px;
    background: #f0f0f0;
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .setting-icon svg:hover{
    color: dodgerblue;
  }