@import url(https://fonts.googleapis.com/css2?family=Prompt:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Prompt', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* * {
  animation-duration: 0s !important;
} */

.MainPage {
  position: relative;
  /* overflow: hidden; */
  height: 100vh;
}
.main-page-layout {
  height: 100%;
}
.main-page-content-layout {
  height: 100%;
  overflow: hidden;
}


.custom-header {
  padding: 0;
  background: #f8f8f8;
  height: auto;
  transition: 0.3s;
  /* display: inline-block */
}
.custom-header-collapsed {
  padding: 0;
  background: #f8f8f8;
  height: 0;
  transition: 0.3s;
}
.wrap-header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  height: 48px;
  text-align: left;
}
.header-button{
  display: flex;
  align-items: center;
}

#console {
  display: flex;
  margin-top: -7px;;
}
#console-buttons {
  min-width: 120px;
  width: 120px;
}
#console-buttons-mobile {
  min-width: 60px;
  width: 60px;
}
#console-filter {
  width: 100%;
  margin-top: -2px;
}

.custom-footer {
  height: 50px;
  background: rgb(249, 252, 255);
  padding: 0;
  transition: 0.3s;
}
.custom-footer-collapsed {
  padding: 0;
  background: rgb(249, 252, 255);
  height: 0;
  transition: 0.3s;
}

.CustomPagination {
    margin: 10px 0px 10px 0px;
}
.custom-sider-opend-logo-small {
  width: 80px;
  height: 80px;
}
.custom-sider-opend-logo-big {
  width: 200px;
  height: 200px;
}
.custom-sider-nectec-logo-small {
  width: 50%;
  margin-top: -36px;
  margin-left: 32px;
  margin-bottom: 13px;
}
.custom-sider-nectec-logo-big {
  width: 50%;
  margin-top: -80px;
  margin-left: 80px;
  margin-bottom: 40px;
}
.custom-sider-opend-logo-small img,
.custom-sider-opend-logo-big img,
.custom-sider-nectec-logo-small img,
.custom-sider-nectec-logo-big img {
  width: 100%;
  height: 100%;
}
.web-version{
  color: #FFF;
  font-size: 0.7rem;
  position: fixed;
  bottom: 50px;
  padding: 5px;
  width: 80px;
}
.is-collapsed{
  padding-left: 24px;
  width: 200px;
  text-align: left;
}

.custom-content {
  background: rgb(249, 252, 255);
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.custom-content-tab {
  width: 100%;
  height: 100%;
}
.hide {
  display: none;
}

.DataContent {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.data-content-card-bg {
  width: 100%;
  height: 100%;
  padding: 0;
}
.data-content-card-bg .ant-card-body {
  padding: 8px;
}
.table-view-table .ant-table-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.table-view-table .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.table-view-table .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

.table-view-table-prevent-resize table {
  height: calc(100vh - 16em) !important;
}
.table-view-table-prevent-resize .ant-table-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.table-view-table-prevent-resize .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.table-view-table-prevent-resize .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
.ant-table-column-sorters {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-view-table .ant-table-body {
  height: calc(100vh - 16em) !important;
}
.table-view-table .ant-table-row {
  min-height: 70px !important;
  height: 70px !important;
}
.table-view-table .ant-table-column-sorter-up, .table-view-table .ant-table-column-sorter-down{
  font-size: 16px !important;
}
.table-view-table .ant-table-column-sorter{
  display: none;
}

.custom-column-sorter{
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color .3s
}
.custom-column-sorter-inner{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.custom-column-sorter-up,
.custom-column-sorter-down,
.custom-sorter {
  font-size: 16px;
}
.custom-column-sorter-up+.custom-column-sorter-down {
  margin-top: -0.3em;
}
.sort-active{
  color: #1890ff;
}
.table-view-table td.ant-table-column-sort{
  background: transparent;
}
#main-filter-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#main-filter-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#main-filter-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
/* .main-filter-wrap-dynamic-item {
  height: 150px;
  overflow-y: scroll;
  padding: 5px;
} */

.main-filter-tags {
  overflow-x: auto;
  white-space: nowrap;
  height: 42px;
  margin-left: 10px;
}
#main-filter-horizontal-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#main-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#main-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

.main-filter-filter-modal .ant-modal-close-x {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}

.icon-type{
    font-size: 13px;
    margin-top: 3px;
    padding-right: 3px;
    padding-left: 3px;
    color: #FFF;
    border-radius: 4px;
}
#map-view {
  width: 100%;
  height: 100%;
  z-index: 100;
}
.MapContent {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-content-show-pagination-drawer-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  z-index: 999;
  border-radius: 50%;
  background-color: #092541;
  border: none;
  outline: none;
  color: white;
  padding: 16px 16px;
  cursor: pointer;
}
.map-content-show-pagination-drawer-button:hover {
  background-color: dodgerblue;
}
.map-content-show-pagination-drawer-button:active {
  background-color: #092541;
}
.map-content-hide-pagination-drawer-button {
  border-radius: 50%;
  background-color: #092541;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  margin: 0 0 16px 16px;
}
.map-content-hide-pagination-drawer-button:hover {
  background-color: dodgerblue;
}
.map-content-hide-pagination-drawer-button:active {
  background-color: #092541;
}
.map-content-pagination-drawer {
  position: absolute;
  bottom: 18px;
  width: 700px;
  height: 270px;
  background-color: white;
  z-index: 999;
  transition: 0.3s;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.25);
  padding-top: 20px;
}
.map-content-menu-icon {
  font-size: 26px;
  margin-top: 1px;
}
.map-content-right-icon {
  font-size: 12px;
}
.map-content-card-wrap-pagination {
  margin: 18px;
}
.full-screen-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 45px !important;
  height: 45px !important;
  z-index: 999;
  border-radius: 50%;
  border: none;
  outline: none;
  color: white;
  padding: 12px 12px;
  cursor: pointer;
}
.main-page-full-screen-button{
  background-color: #092541;
}
.main-page-full-screen-button:hover, .main-page-exit-full-screen-button:hover {
  background-color: dodgerblue;
  color: #FFF;
}
.main-page-full-screen-button:active {
  background-color: #092541;
}
.main-page-exit-full-screen-button{
  background-color: #FFF;
  color: #40a9ff;
}
.main-page-full-screen-icon {
  font-size: 20px !important;
}
.map-wrap-header{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: baseline;
  width: 100vw;
  height: 65px;
  text-align: left;
}
.header-button{
  display: flex;
  align-items: center;
}
#map-view {
  width: 100%;
  height: 100%;
  z-index: 100;
}
.MapView {
  width: 100%;
  /* height: 100vh; */
  height: calc(100vh - 97px);
  position: relative;
}
.MapViewFullScreen{
  height: 100vh;
}
.leaflet-control-layers-base {
  text-align: start;
}
.map-view-timeline-button {
  border: none;
  outline: none;
  background-color: white;
  cursor: pointer;
}
.map-view-timeline-button:hover {
  border: none;
  outline: none;
  background-color: rgb(226, 226, 226);
  cursor: pointer;
}
.map-view-timeline-button:active {
  border: none;
  outline: none;
  background-color: rgb(180, 180, 180);
  cursor: pointer;
}
.map-view-create-data-group-button {
  border: none;
  outline: none;
  background-color: white;
  cursor: pointer;
}
.map-view-create-data-group-button:hover {
  border: none;
  outline: none;
  background-color: rgb(226, 226, 226);
  cursor: pointer;
}
.map-view-create-data-group-button:active {
  border: none;
  outline: none;
  background-color: rgb(180, 180, 180);
  cursor: pointer;
}
.leaflet-control-layers-scrollbar {
  overflow-y: hidden !important;
  padding: 0 !important;
}
.leaflet-control-layers-list {
  text-align: start;
}
.map-view-group-header {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
}
.map-view-group-divider {
  width: 100%;
  border-bottom: 1px solid black;
  position: absolute;
}
.map-view-marker-popup-wraper {
  word-break: break-word;
}

@media screen and (max-width: 575px) {
  .MapView {
    height: calc(100vh - 162px);
  }
}

.radio-custom label{
  width: 100px;
}
.radio-custom .ant-radio-button-wrapper:first-child{
  border-radius: 30px 0 0 30px;
}
.radio-custom .ant-radio-button-wrapper:last-child{
  border-radius: 0 30px 30px 0;
}
.MapGroupingForm::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.MapGroupingForm::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.MapGroupingForm::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
.MapGroupingForm {
  height: 300px;
  overflow-y: scroll;
  padding: 5px;
}

#table-select-data-modal-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#table-select-data-modal-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#table-select-data-modal-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
.table-select-data-modal-condition-container {
  height: 60px;
  overflow-y: scroll;
}
.table-select-data-modal-filter-card .ant-card-body {
    padding: 10px 0px 10px 10px;
}
.table-select-data-empty-text {
  height: 500px;
}
.table-select-data .ant-table-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.table-select-data .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.table-select-data .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
.ant-table-column-sorters {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-select-data-prevent-resize table {
  height: 500px !important;
}
.table-select-data-prevent-resize .ant-table-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.table-select-data-prevent-resize .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.table-select-data-prevent-resize .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

/* .table-select-data-no-data .ant-table-expanded-row-fixed {
    height: 500px !important;
    width: 600px !important;
}
.table-select-data-no-data .ant-table-body {
    overflow-y: hidden !important;
}
.table-select-data-no-data .ant-table-body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.table-select-data-no-data .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.table-select-data-no-data .ant-table-body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
} */

#main-filter-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#main-filter-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#main-filter-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
/* .main-filter-wrap-dynamic-item {
  max-height: 150px;
  overflow-y: scroll;
  padding: 5px;
} */

.main-filter-tags {
  overflow-x: auto;
  white-space: nowrap;
  height: 42px;
  margin-left: 10px;
}
#main-filter-horizontal-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#main-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#main-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

.main-filter-filter-modal .ant-modal-close-x {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}

.setting-layout{
    position: fixed;
    bottom: 10px;
    right: 20px;
    display: flex;
    z-index: 999;
    grid-gap: 15px;
    gap: 15px;
    align-items: center;
  }
  .setting-icon svg{
    width: 2rem;
    height: 2rem;
    color: #555;
    cursor: pointer;
  }
  .full-screen-chart{
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 50%;
    border: none;
    outline: none;
    color: white;
    padding: 12px 12px;
    cursor: pointer;
  }
  .full-screen-chart-button{
    background-color: #092541;
  }
  .full-screen-chart-button:hover, .exit-full-screen-button:hover {
    background-color: dodgerblue;
    color: #FFF;
  }
  .full-screen-chart-button:active {
    background-color: #092541;
  }
  .exit-full-screen-button{
    background-color: #FFF;
    color: #40a9ff;
  }
  .full-screen-icon {
    font-size: 18px !important;
  }
  .setting-sub-menu{
    padding: 7px 20px;
    border-radius: 5px;
    background: #f0f0f0;
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    align-items: center;
  }
  .setting-icon svg:hover{
    color: dodgerblue;
  }
.ChartContent {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgb(248, 248, 248);
  position: relative;
}
.wrap-header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  height: 48px;
  text-align: left;
}
.header-button{
  display: flex;
  align-items: center;
}

.ChartLayout {
  width: 100%;
  height: calc(100vh - 48px);
  position: absolute;
  top: 48px;
  left: 0;
  background-color: white;
}
.fullScreenLayout{
  height: 100vh;
  top: 0;
}

.chart-layout-rowcol-droppable {
  border-bottom: 1px solid #e6e6e6;
}

.chart-layout-items-droppable {
  height: calc(100vh - 136px);
}

.chart-layout-list-container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.chart-layout-list-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.chart-layout-list-container::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
.chart-layout-list-header {
  margin-top: 10px;
  margin-bottom: 10px;
}
.ant-popover-message-title {
  padding: 0;
}
.ant-popover-buttons {
  text-align: center;
}

.chart-filter-filter-modal .ant-modal-close-x {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}
#test + div {
  display: none !important;
}
.disabled-text{
  color: #DDD;
}
.chart-border-top{
  border-top: 1px solid #D3D3D3;
  height: 100%;
  overflow-y: scroll;
}
.chart-border{
  border: 1px solid #D3D3D3;
  height: calc(100vh - 210px);
  overflow-y: scroll;
}
.chart-layout{
  height: calc(100vh - 48px);
}
.full-screen-layout{
  height: 100vh;
}
.loading-wrapper{
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 9;
    padding-bottom: 10px;
}
.bg-loading{
    height: 100%;
    background: rgb(255 255 255);
    /* opacity: 0.7; */
}
.loading-spin{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
#chart-filter-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#chart-filter-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#chart-filter-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
/* .chart-filter-wrap-dynamic-item {
  height: 150px;
  overflow-y: scroll;
  padding: 5px;
} */

.chart-filter-tags {
  overflow-x: auto;
  white-space: nowrap;
  height: 42px;
}
#chart-filter-horizontal-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#chart-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#chart-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

.chart-button-menu-icon {
    font-size: 24px;
    margin-top: 12px;
}
.custom-button.ant-radio-button-wrapper-disabled{
    color: inherit;
    background-color: transparent;
    cursor: default;
}
.chart-layout-chip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}
.remove-attr-btn{
  position: absolute;
  border-radius: 50%;
  padding: 2px;
  font-size: 10px;
  height: 15px;
  display: flex;
  width: 15px;
  top: 0;
  right: 0;
  background: #FFF;
  margin-top: -3px;
  margin-right: -3px;
  color: #000;
  border: 1px solid #817f7f;
}

.LineChart {
  position: relative;
  overflow: hidden;
}



.card-label{
    word-break: break-all;
}
.card-label:focus{
    outline: 2px solid white;
}
.edit-content{
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 15px;
    padding: 0px 5px;
    border: 0;
    height: 25px;
    background: transparent;
    font-size: 18px;
    color: #FFF;
}
.edit-content:hover{
    color: #c7c7c7;
    background: transparent;
}
.edit-content:focus,.edit-content:active{
    background: transparent;
    color: #FFF;
}
.card-label{
    word-break: break-all;
}
.card-label:focus{
    outline: 2px solid white;
}
#heatmap{
    height: 100%;
}
.legend {
    line-height: 18px;
    color: #555;
    background-color: rgba(255, 255, 255, .6);
    padding: 5px;
}
.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}
.heatmap-tooltip p{
    margin-bottom: 0;
    text-align: left;
}
.heatmap-tooltip b{
    color: #002766;
}
.leaflet-interactive {
    outline: none !important;
    box-shadow: none !important;
}
.map-back-btn{
    cursor: pointer;
}
.clickable{
    cursor: pointer;
}
.clickable:hover{
    text-decoration: underline;
    color: #119fee;
}
.normal-text{
    cursor: default;
}
.separate-icon{
    color: #a0a0a0;
    padding: 0px 3px;
    font-size: 16px;
    line-height: 1;
    vertical-align: middle;
}
.map-title{
    position: absolute;
    left: 55px;
    top: 12px;
    padding: 2px 10px;
    background: rgba(255,255,255,.65);
    /* border-radius: 30px; */
    display: flex;
    z-index: 405;
    font-size: 12px;
}
#map-wrapper{
    height: 100%;
    position: relative;
}
#heatmap-wrapper{
    height: 100%;
}
.map-mode{
    border-radius: 30px;
    color: #119fee;
}
.map-mode-wrapper{
    position: absolute;
    right: 10px;
    top: 12px;
}
.region-mode{
    border-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 0;
    /* border-right: 0; */
}
.province-mode{
    border-radius: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 0;
    /* border-left: 0; */
}
.region-mode:hover, .province-mode:hover{
    background-color: #FFF;
}
.disabled-mode{
    background: #c1c1c1;
    color: #f4f2f2;
}
.active-mode{
    color: #119fee;
}

#chart-filter-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#chart-filter-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#chart-filter-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

.chart-filter-tags {
  overflow-x: auto;
  white-space: nowrap;
  height: 42px;
}
#chart-filter-horizontal-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#chart-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#chart-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

.SettingContent {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.SettingContent::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.SettingContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.SettingContent::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

.custom-navbar-xs-opend-logo {
  float: left;
  width: 100px;
  height: 64px;
  margin-top: -2px;
  margin-left: -15px;
}
.custom-navbar-xs-nectec-logo {
  float: left;
  height: 10px;
  margin-left: -45px;
  margin-top: 13px;
}
.custom-navbar-xs-opend-logo img,
.custom-navbar-xs-nectec-logo img {
    height: 100%;
}

