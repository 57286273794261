.custom-content {
  background: rgb(249, 252, 255);
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.custom-content-tab {
  width: 100%;
  height: 100%;
}
.hide {
  display: none;
}
