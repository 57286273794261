.card-label{
    word-break: break-all;
}
.card-label:focus{
    outline: 2px solid white;
}
#heatmap{
    height: 100%;
}
.legend {
    line-height: 18px;
    color: #555;
    background-color: rgba(255, 255, 255, .6);
    padding: 5px;
}
.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}
.heatmap-tooltip p{
    margin-bottom: 0;
    text-align: left;
}
.heatmap-tooltip b{
    color: #002766;
}
.leaflet-interactive {
    outline: none !important;
    box-shadow: none !important;
}
.map-back-btn{
    cursor: pointer;
}
.clickable{
    cursor: pointer;
}
.clickable:hover{
    text-decoration: underline;
    color: #119fee;
}
.normal-text{
    cursor: default;
}
.separate-icon{
    color: #a0a0a0;
    padding: 0px 3px;
    font-size: 16px;
    line-height: 1;
    vertical-align: middle;
}
.map-title{
    position: absolute;
    left: 55px;
    top: 12px;
    padding: 2px 10px;
    background: rgba(255,255,255,.65);
    /* border-radius: 30px; */
    display: flex;
    z-index: 405;
    font-size: 12px;
}
#map-wrapper{
    height: 100%;
    position: relative;
}
#heatmap-wrapper{
    height: 100%;
}
.map-mode{
    border-radius: 30px;
    color: #119fee;
}
.map-mode-wrapper{
    position: absolute;
    right: 10px;
    top: 12px;
}
.region-mode{
    border-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 0;
    /* border-right: 0; */
}
.province-mode{
    border-radius: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 0;
    /* border-left: 0; */
}
.region-mode:hover, .province-mode:hover{
    background-color: #FFF;
}
.disabled-mode{
    background: #c1c1c1;
    color: #f4f2f2;
}
.active-mode{
    color: #119fee;
}