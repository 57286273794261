.DataContent {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.data-content-card-bg {
  width: 100%;
  height: 100%;
  padding: 0;
}
.data-content-card-bg .ant-card-body {
  padding: 8px;
}