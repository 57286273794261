.ChartLayout {
  width: 100%;
  height: calc(100vh - 48px);
  position: absolute;
  top: 48px;
  left: 0;
  background-color: white;
}
.fullScreenLayout{
  height: 100vh;
  top: 0;
}

.chart-layout-rowcol-droppable {
  border-bottom: 1px solid #e6e6e6;
}

.chart-layout-items-droppable {
  height: calc(100vh - 136px);
}

.chart-layout-list-container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.chart-layout-list-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.chart-layout-list-container::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
.chart-layout-list-header {
  margin-top: 10px;
  margin-bottom: 10px;
}
.ant-popover-message-title {
  padding: 0;
}
.ant-popover-buttons {
  text-align: center;
}

.chart-filter-filter-modal .ant-modal-close-x {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}
#test + div {
  display: none !important;
}
.disabled-text{
  color: #DDD;
}
.chart-border-top{
  border-top: 1px solid #D3D3D3;
  height: 100%;
  overflow-y: scroll;
}
.chart-border{
  border: 1px solid #D3D3D3;
  height: calc(100vh - 210px);
  overflow-y: scroll;
}
.chart-layout{
  height: calc(100vh - 48px);
}
.full-screen-layout{
  height: 100vh;
}
.loading-wrapper{
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 9;
    padding-bottom: 10px;
}
.bg-loading{
    height: 100%;
    background: rgb(255 255 255);
    /* opacity: 0.7; */
}
.loading-spin{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}