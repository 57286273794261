.ChartContent {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgb(248, 248, 248);
  position: relative;
}
.wrap-header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  height: 48px;
  text-align: left;
}
.header-button{
  display: flex;
  align-items: center;
}
