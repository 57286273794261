#main-filter-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#main-filter-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#main-filter-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
/* .main-filter-wrap-dynamic-item {
  max-height: 150px;
  overflow-y: scroll;
  padding: 5px;
} */

.main-filter-tags {
  overflow-x: auto;
  white-space: nowrap;
  height: 42px;
  margin-left: 10px;
}
#main-filter-horizontal-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#main-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#main-filter-horizontal-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}

.main-filter-filter-modal .ant-modal-close-x {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}
