.SettingContent {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.SettingContent::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
.SettingContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
.SettingContent::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
