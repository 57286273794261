.custom-sider-opend-logo-small {
  width: 80px;
  height: 80px;
}
.custom-sider-opend-logo-big {
  width: 200px;
  height: 200px;
}
.custom-sider-nectec-logo-small {
  width: 50%;
  margin-top: -36px;
  margin-left: 32px;
  margin-bottom: 13px;
}
.custom-sider-nectec-logo-big {
  width: 50%;
  margin-top: -80px;
  margin-left: 80px;
  margin-bottom: 40px;
}
.custom-sider-opend-logo-small img,
.custom-sider-opend-logo-big img,
.custom-sider-nectec-logo-small img,
.custom-sider-nectec-logo-big img {
  width: 100%;
  height: 100%;
}
.web-version{
  color: #FFF;
  font-size: 0.7rem;
  position: fixed;
  bottom: 50px;
  padding: 5px;
  width: 80px;
}
.is-collapsed{
  padding-left: 24px;
  width: 200px;
  text-align: left;
}
