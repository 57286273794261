#map-view {
  width: 100%;
  height: 100%;
  z-index: 100;
}
.MapContent {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-content-show-pagination-drawer-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  z-index: 999;
  border-radius: 50%;
  background-color: #092541;
  border: none;
  outline: none;
  color: white;
  padding: 16px 16px;
  cursor: pointer;
}
.map-content-show-pagination-drawer-button:hover {
  background-color: dodgerblue;
}
.map-content-show-pagination-drawer-button:active {
  background-color: #092541;
}
.map-content-hide-pagination-drawer-button {
  border-radius: 50%;
  background-color: #092541;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  margin: 0 0 16px 16px;
}
.map-content-hide-pagination-drawer-button:hover {
  background-color: dodgerblue;
}
.map-content-hide-pagination-drawer-button:active {
  background-color: #092541;
}
.map-content-pagination-drawer {
  position: absolute;
  bottom: 18px;
  width: 700px;
  height: 270px;
  background-color: white;
  z-index: 999;
  transition: 0.3s;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.25);
  padding-top: 20px;
}
.map-content-menu-icon {
  font-size: 26px;
  margin-top: 1px;
}
.map-content-right-icon {
  font-size: 12px;
}
.map-content-card-wrap-pagination {
  margin: 18px;
}
.full-screen-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 45px !important;
  height: 45px !important;
  z-index: 999;
  border-radius: 50%;
  border: none;
  outline: none;
  color: white;
  padding: 12px 12px;
  cursor: pointer;
}
.main-page-full-screen-button{
  background-color: #092541;
}
.main-page-full-screen-button:hover, .main-page-exit-full-screen-button:hover {
  background-color: dodgerblue;
  color: #FFF;
}
.main-page-full-screen-button:active {
  background-color: #092541;
}
.main-page-exit-full-screen-button{
  background-color: #FFF;
  color: #40a9ff;
}
.main-page-full-screen-icon {
  font-size: 20px !important;
}
.map-wrap-header{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: baseline;
  width: 100vw;
  height: 65px;
  text-align: left;
}
.header-button{
  display: flex;
  align-items: center;
}