.custom-footer {
  height: 50px;
  background: rgb(249, 252, 255);
  padding: 0;
  transition: 0.3s;
}
.custom-footer-collapsed {
  padding: 0;
  background: rgb(249, 252, 255);
  height: 0;
  transition: 0.3s;
}
