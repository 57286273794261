.chart-layout-chip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}
.remove-attr-btn{
  position: absolute;
  border-radius: 50%;
  padding: 2px;
  font-size: 10px;
  height: 15px;
  display: flex;
  width: 15px;
  top: 0;
  right: 0;
  background: #FFF;
  margin-top: -3px;
  margin-right: -3px;
  color: #000;
  border: 1px solid #817f7f;
}
