.card-label{
    word-break: break-all;
}
.card-label:focus{
    outline: 2px solid white;
}
.edit-content{
    position: absolute;
    top: 1px;
    right: 1px;
    font-size: 15px;
    padding: 0px 5px;
    border: 0;
    height: 25px;
    background: transparent;
    font-size: 18px;
    color: #FFF;
}
.edit-content:hover{
    color: #c7c7c7;
    background: transparent;
}
.edit-content:focus,.edit-content:active{
    background: transparent;
    color: #FFF;
}