.custom-navbar-xs-opend-logo {
  float: left;
  width: 100px;
  height: 64px;
  margin-top: -2px;
  margin-left: -15px;
}
.custom-navbar-xs-nectec-logo {
  float: left;
  height: 10px;
  margin-left: -45px;
  margin-top: 13px;
}
.custom-navbar-xs-opend-logo img,
.custom-navbar-xs-nectec-logo img {
    height: 100%;
}
