.custom-header {
  padding: 0;
  background: #f8f8f8;
  height: auto;
  transition: 0.3s;
  /* display: inline-block */
}
.custom-header-collapsed {
  padding: 0;
  background: #f8f8f8;
  height: 0;
  transition: 0.3s;
}
.wrap-header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  height: 48px;
  text-align: left;
}
.header-button{
  display: flex;
  align-items: center;
}

#console {
  display: flex;
  margin-top: -7px;;
}
#console-buttons {
  min-width: 120px;
  width: 120px;
}
#console-buttons-mobile {
  min-width: 60px;
  width: 60px;
}
#console-filter {
  width: 100%;
  margin-top: -2px;
}
