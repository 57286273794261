#table-select-data-modal-scrollbar-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}
#table-select-data-modal-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(238, 238, 238);
}
#table-select-data-modal-scrollbar-style::-webkit-scrollbar-thumb:hover {
  background-color: rgb(194, 194, 194);
}
.table-select-data-modal-condition-container {
  height: 60px;
  overflow-y: scroll;
}
.table-select-data-modal-filter-card .ant-card-body {
    padding: 10px 0px 10px 10px;
}