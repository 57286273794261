#map-view {
  width: 100%;
  height: 100%;
  z-index: 100;
}
.MapView {
  width: 100%;
  /* height: 100vh; */
  height: calc(100vh - 97px);
  position: relative;
}
.MapViewFullScreen{
  height: 100vh;
}
.leaflet-control-layers-base {
  text-align: start;
}
.map-view-timeline-button {
  border: none;
  outline: none;
  background-color: white;
  cursor: pointer;
}
.map-view-timeline-button:hover {
  border: none;
  outline: none;
  background-color: rgb(226, 226, 226);
  cursor: pointer;
}
.map-view-timeline-button:active {
  border: none;
  outline: none;
  background-color: rgb(180, 180, 180);
  cursor: pointer;
}
.map-view-create-data-group-button {
  border: none;
  outline: none;
  background-color: white;
  cursor: pointer;
}
.map-view-create-data-group-button:hover {
  border: none;
  outline: none;
  background-color: rgb(226, 226, 226);
  cursor: pointer;
}
.map-view-create-data-group-button:active {
  border: none;
  outline: none;
  background-color: rgb(180, 180, 180);
  cursor: pointer;
}
.leaflet-control-layers-scrollbar {
  overflow-y: hidden !important;
  padding: 0 !important;
}
.leaflet-control-layers-list {
  text-align: start;
}
.map-view-group-header {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
}
.map-view-group-divider {
  width: 100%;
  border-bottom: 1px solid black;
  position: absolute;
}
.map-view-marker-popup-wraper {
  word-break: break-word;
}

@media screen and (max-width: 575px) {
  .MapView {
    height: calc(100vh - 162px);
  }
}

.radio-custom label{
  width: 100px;
}
.radio-custom .ant-radio-button-wrapper:first-child{
  border-radius: 30px 0 0 30px;
}
.radio-custom .ant-radio-button-wrapper:last-child{
  border-radius: 0 30px 30px 0;
}